import { Link } from "react-router-dom";
import connectwithus from "../assets/images/fy-connexion.png";

function ConnectWithUs() {
  return (
    <div className="flex flex-col p-5">
      <div className="self-center w-full max-w-screen-xl mb-20 md:mb-10 mx-auto">
        <div className="flex flex-col md:flex-row gap-5 md:gap-0">
          <section className="flex flex-col items-stretch w-full md:w-1/2 md:mt-0">
            <h1 className="text-green-apple mb-8 text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-playfair">
              Ready to take your business to the next level?
            </h1>
            <p className="text-taupe mb-16 font-poppins text-base sm:text-lg md:text-xl lg:text-1xl xl:text-2xl">
              Allow us to infuse fresh perspectives, uncover hidden opportunities, and craft tailored strategies that
              will propel you toward your objectives.
            </p>
            <Link to="/contact">
              <button className="bg-green-apple text-white text-2xl font-poppins py-2 px-6 rounded-3xl">
                Contact Us!
              </button>
            </Link>
          </section>
          <section className="flex flex-col items-stretch w-full md:w-1/2 mt-10 md:mt-0 md:ml-5">
            <img
              loading="lazy"
              src={connectwithus}
              alt="Connect with us"
              className="w-full object-cover object-center"
            />
          </section>
        </div>
      </div>
    </div>
  );
}

export default ConnectWithUs;
