function MotivatedTeam() {
  return (
    <div className="px-5 py-8">
      <section className="flex flex-col items-center max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row gap-10 lg:gap-20 text-center">
          <h1 className="text-green-apple text-xl sm:text-2xl md:text-3xl lg:text-4xl font-playfair mb-10 w-full">
            Highly Motivated Team with Innovative Ideas
          </h1>
          <p className="text-taupe text-lg sm:text-xl md:text-2xl lg:text-2xl font-poppins mb-10 w-full">
            Our enthusiasm fuels our quest for creative solutions that promise speed and efficacy. Count on us as your
            steadfast ally in the journey to shared triumph.
          </p>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-10">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7c83224d939378a9974882dd4c75fe558fe782830d2cd44bfde21f3ae37b3b7e?apiKey=5400aca826c74d1e87d6e429f94c3e3f"
            className="w-full md:w-1/3 h-64 object-cover"
            sizes="(max-width: 640px) 100vw, (min-width: 641px) and (max-width: 768px) 50vw, (min-width: 769px) 33vw"
            alt="presentation"
          />
          <div className="bg-green-apple h-px w-full md:hidden my-5"></div>
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5d22474c72d1a499792642fda4ba9f4192a80607801710bd126e804139cb9a4e?apiKey=5400aca826c74d1e87d6e429f94c3e3f"
            className="w-full md:w-1/3 h-64 object-cover"
            sizes="(max-width: 640px) 100vw, (min-width: 641px) and (max-width: 768px) 50vw, (min-width: 769px) 33vw"
            alt="presenting"
          />
          <div className="bg-green-apple h-px w-full md:hidden my-5"></div>
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/211948f93b81f04ce11aaa392d35d657bbc3afbfbde5673eb2cf7a7532629e28?apiKey=5400aca826c74d1e87d6e429f94c3e3f"
            className="w-full md:w-1/3 h-64 object-cover"
            sizes="(max-width: 640px) 100vw, (min-width: 641px) and (max-width: 768px) 50vw, (min-width: 769px) 33vw"
            alt="observing"
          />
        </div>
      </section>
    </div>
  );
}

export default MotivatedTeam;
