import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";


const customIcon = new Icon({
  // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  iconUrl: require("../assets/images/download.png"),
  iconSize: [38, 38], 
});

const MapComponent = () => {
  const position = [51.51498569182061, -0.123557131102533]; 

  return (
    <div className=" text-taupe ">
      <MapContainer
        center={position}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: "70vh", width: "90%", borderRadius: "2rem" }}
      >
        <TileLayer
          attribution="Google Maps"
          url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" // regular
            // url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" // satellite
          //   url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" // terrain
          maxZoom={20}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <Marker position={position} icon={customIcon}>
          <Popup>
            The office is here : <br /> 272 Bath Street, Glasgow, Scotland, G2 4JR
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapComponent;
