import React, { useState } from "react";
import consulting from "../assets/images/fy-consulting.png";
import turning from "../assets/images/fy-360d.png";
import projectManagement from "../assets/images/fy-projectManagement.png";
import { Link } from "react-router-dom";

export default function Service() {
  const [readMore, setReadMore] = useState({});

  const toggleReadMore = (key) => {
    setReadMore((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  const isAnyServiceExpanded = () => {
    return Object.values(readMore).some((state) => state);
  };

  const services = [
    {
      key: "businessDevelopment",
      src: consulting,
      alt: "consulting",
      title: "Business Development",
      description:
        "Our Business Development services are tailored to expand your market reach and enhance your competitive edge. We navigate through market analysis, opportunity identification, ",
      fullText: [
        "and strategic partnership cultivation.",
        "Fykazi Ltd focuses on aligning your business objectives with market opportunities to drive sustainable growth. Trust us to not only envision but also execute business strategies that deliver tangible results and position you at the forefront of industry innovation.",
      ],
    },
    {
      key: "projectManagement",
      src: projectManagement,
      alt: "projectManagement",
      title: "Project Oversight",
      description:
        "Fykazi Ltd specializes in full-spectrum project management, guiding projects from initiation through to successful completion. Our services include detailed scope definition,",
      fullText:
        " cost estimation, risk management, and meticulous planning and scheduling. We coordinate and execute each phase to meet unique project demands, focusing on milestone creation and adherence to ensure timely, on-budget delivery.",
    },
    {
      key: "turning",
      src: turning,
      alt: "turning",
      title: "360° MANAGEMENT",
      description:
        "Our 360° management services provide a comprehensive approach to optimizing your business operations. With our expert guidance and support, you can gain:",
      fullText: [
        " a complete view of your organization and make data-driven decisions that improve efficiency, productivity, and profitability.",
        "a comprehensive support to optimize operations and boost profitability.",
        "a holistic approach to optimize your operations and enhance your bottom line.",
      ],
    },
  ];

  const expandedClass = isAnyServiceExpanded() ? "mt-2" : "mt-20";

  return (
    <main className="flex flex-col items-center px-5 py-8">
      <section className="text-green-apple text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-playfair mt-5 sm:w-[700px] w-full">
        We Help You Build a Business Of Your Dreams With Our Services
      </section>
      <div className="grid grid-cols-1 gap-10 md:grid-cols-3 px-5 pb-3 pt-10">
        {services.map((service) => (
          <div key={service.key} className="flex flex-col items-center">
            <div className="w-48 h-48 flex items-center justify-center mb-5">
              <img loading="lazy" src={service.src} alt={service.alt} className="object-contain w-full h-full" />
            </div>
            <h2 className="text-green-apple text-xl md:text-2xl font-playfair mb-4">{service.title}</h2>
            <p className="text-taupe text-base md:text-lg font-poppins leading-snug text-left">
              {service.description}
              {readMore[service.key] && (
                <>
                  {Array.isArray(service.fullText)
                    ? service.fullText.map((text, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && <br />}
                          {text}
                        </React.Fragment>
                      ))
                    : service.fullText}
                </>
              )}
            </p>
            <button onClick={() => toggleReadMore(service.key)} className="text-green-apple underline mt-2">
              {readMore[service.key] ? "Read Less" : "Read More"}
            </button>
          </div>
        ))}
      </div>
      <section
        className={`flex flex-col md:flex-row items-center justify-between w-full gap-5 ${expandedClass} transition-all duration-100 text-center`}
      >
        <h2 className="text-green-apple mb-8 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-playfair">
          Ready To Start Your Business
        </h2>
        <div className="bg-green-apple flex items-center justify-center w-full md:w-auto px-5 py-3 rounded-2xl hover:bg-amber-600 transition-all duration-300">
          <Link to="/contact">
            <button className="text-white text-xl md:text-2xl font-poppins whitespace-nowrap focus:outline-none">
              Contact Us
            </button>
          </Link>
        </div>
      </section>
    </main>
  );
}
