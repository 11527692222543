import React, { useEffect, useState } from "react";
import { useForm } from "@formspree/react";

function Subscription() {
  const [email, setEmail] = useState("");
  const [state, handleSubmit] = useForm("maygkqav");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setEmail("");
      }, 2000);
    }
  }, [state.succeeded]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      email: email,
      message: "I’m Interested", 
    });
  };

  return (
    <div className="flex flex-col md:flex-row px-5 py-10 items-center justify-evenly">
      <h1 className="text-green-apple text-4xl font-playfair mb-2 md:mb-0 md:pr-10 text-center md:text-left md:text-4xl md:pl-5 ">
        Subscribe
      </h1>

      <form onSubmit={handleFormSubmit} className="flex flex-col md:flex-row md:items-center gap-5 w-full md:w-auto bg-white p-5 rounded-3xl">
        <label htmlFor="email" className="sr-only">
          Email Address
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email Address"
          className="p-2 text-lg rounded-md text-black"
          value={email}
          onChange={handleEmailChange}
        />

        <button type="submit" disabled={state.submitting} className="bg-green-apple text-white text-2xl md:text-4xl py-2 px-5 rounded-3xl whitespace-nowrap">
          I’m Interested
        </button>
      </form>

      {showSuccessMessage && (
        <div className="mt-4 bg-green-500 text-white p-2 rounded-md">
          Thank you for subscribing!
        </div>
      )}
    </div>
  );
}

export default Subscription;
