import React, { useEffect, useState } from "react";
import MapComponent from "../component/MapComponent";
import { useForm, ValidationError } from "@formspree/react";

export default function Contact() {
  const [state, handleSubmit] = useForm("maygkqav");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setEmail("");
        setFirstName("");
        setLastName("");
        setSubject("");
        setMessage("");
      }, 2000);
    }
  }, [state.succeeded]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "subject":
        setSubject(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const formatMessage = () => {
    return `First Name: ${firstName}\nLast Name: ${lastName}\nSubject: ${subject}\nMessage: ${message}`;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit({
      email,
      message: formatMessage(),
    });
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center items-stretch gap-8 p-8">
      <div className="flex-1 p-4">
        <MapComponent />
      </div>
      <div className="flex-1 p-4">
        <h2 className="text-2xl text-taupe font-bold mb-4">Get in Touch</h2>
        <form onSubmit={handleFormSubmit} className=" text-taupe p-4 rounded-md">
          <div className="mb-4">
            <div className="mb-4 lg:w-1/2">
              <label htmlFor="firstName" className="block text-sm font-bold mb-2">
                First Name
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md text-black"
                id="firstName"
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4 lg:w-1/2">
              <label htmlFor="lastName" className="block text-sm font-bold mb-2">
                Last Name
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md text-black"
                id="lastName"
                type="text"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className=" text-sm font-bold mb-2">
              Email Address
            </label>
            <input
              className="w-full p-2 border border-gray-300 rounded-md text-black"
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="block text-sm font-bold mb-2">
              Subject
            </label>
            <input
              className="w-full p-2 border border-gray-300 rounded-md text-black"
              id="subject"
              type="text"
              name="subject"
              value={subject}
              onChange={handleInputChange}
            />
            <ValidationError prefix="Subject" field="subject" errors={state.errors} />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-bold mb-2">
              Message
            </label>
            <textarea
              className="w-full p-2 border border-gray-300 rounded-md text-black"
              id="message"
              name="message"
              rows="10"
              value={message}
              onChange={handleInputChange}
            />
            <ValidationError prefix="Message" field="message" errors={state.errors} />
          </div>

          <div className="flex justify-center sm:justify-center lg:justify-start md:justify-center mt-4">
            <button
              type="submit"
              disabled={state.submitting}
              className="bg-green-apple text-white py-2 px-4 rounded-md hover:bg-orange-600 transition-colors duration-300"
            >
              Submit
            </button>
          </div>

          {showSuccessMessage && (
            <div className="mt-4 bg-green-500 text-white p-2 rounded-md">
              Your message has been submitted successfully!
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
